<template>
  <div>
    <!-- section (리컬럼 소개) -->
    <div class="d-flex align-start justify-center" style="background-color: #F9FAFC">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex flex-column align-center" style="width: 60%; padding: 160px 0px 100px 0px">
        <div style="font-size: 48px; font-weight: 700" class="pb-6">
          플랫폼에서 모든 관리를 한 번에
        </div>
        <div style="font-size: 24px; font-weight: 400" class="text-center pb-12">
          웹 기반 SaaS(Soft-ware-as-a-Service)플랫폼 리컬럼은 데이터 통합부터 인공지능 분석 솔루션까지 한 번에 제공합니다. <br>
          복잡한 설치, 세팅, 배포 및 적용에서 자유로워지세요. 
        </div>
        <v-btn color="#367BF5" x-large dark @click="goRecolumn()">바로가기</v-btn>
        <div class="d-flex justify-space-between align-center" style="width: 100%; padding: 100px 0px">
          <div class="d-flex justify-center align-center" style="width: 48%">
            <img src="@/assets/img/recolumn3.png" style="width: 100%; height: auto">
          </div>
          <div class="d-flex justify-center align-center" style="width: 48%">
            <img src="@/assets/img/recolumn2.png" style="width: 100%; height: auto">
          </div>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex flex-column align-center" style="width: 80%; padding: 160px 0px 100px 0px">
        <div style="font-size: 48px; font-weight: 700" class="pb-6">
          플랫폼에서 모든 관리를 한 번에
        </div>
        <div style="font-size: 24px; font-weight: 400" class="text-center pb-12">
          웹 기반 SaaS(Soft-ware-as-a-Service)플랫폼 리컬럼은 데이터 통합부터 인공지능 분석 솔루션까지 한 번에 제공합니다. <br>
          복잡한 설치, 세팅, 배포 및 적용에서 자유로워지세요. 
        </div>
        <v-btn color="#367BF5" x-large dark @click="goRecolumn()">바로가기</v-btn>
        <div class="d-flex justify-space-between align-center" style="width: 100%; padding: 100px 0px">
          <div class="d-flex justify-center align-center" style="width: 48%">
            <img src="@/assets/img/recolumn3.png" style="width: 100%; height: auto">
          </div>
          <div class="d-flex justify-center align-center" style="width: 48%">
            <img src="@/assets/img/recolumn2.png" style="width: 100%; height: auto">
          </div>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center justify-center" style="width: 90%; padding: 60px 0px 60px 0px">
        <div style="font-size: 24px; font-weight: 700" class="text-center pb-6">
          플랫폼에서 모든 관리를 한 번에
        </div>
        <div style="font-size: 16px; font-weight: 400" class="text-center pb-6">
          웹 기반 SaaS(Soft-ware-as-a-Service)플랫폼 리컬럼은 데이터 통합부터 인공지능 분석 솔루션까지 한 번에 제공합니다. <br>
          복잡한 설치, 세팅, 배포 및 적용에서 자유로워지세요. 
        </div>
        <div style="width: 100%" class="pb-6">
          <img src="@/assets/img/recolumn2.png" style="width: 100%; height: auto">
        </div>
        <v-btn color="#367BF5" dark @click="goRecolumn()">바로가기</v-btn>
      </div>
    </div>

    <!-- 리컬럼 플랫폼 활용 사례 -->
    <div class="usecase-title text-center" style="font-weight: 700; font-size: 48px; padding: 160px 0px 0px 0px">리컬럼 플랫폼 활용 사례</div>

    <!-- section (연관 단어 관리 및 전문 용어 추천) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example1.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">연관 단어 관리 및 전문 용어 추천</div>
            <div class="usecase-section-sub" style="font-weight: 400; font-size: 18px">딥러닝 언어모델과 UMLS 의학용어 기반</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              데이터에 혼재되어있는 연관 단어를 목적에 맞게 통합하여 정리해주어 업무 효율이 향상됩니다. 특히 바이오메디컬 분야에서 NIH (미국 국립 보건원) UMLS (Unified Medical Language System) 라이센스를 확보해 더 정확도 높은 용어 관리 및 추천을 제공합니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (분산된 데이터베이스 통합) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example2.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">분산된 데이터베이스 통합</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              분산되어있는 데이터베이스에서 중복된 내용은 생략해 하나의 데이터 베이스로 통합하여 원하는 정보를 한 눈에 얻을 수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (원하는 데이터베이스 형식으로 자동 변환) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 0px 0px 40px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example3.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">원하는 데이터베이스 형식으로 자동 변환</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              데이터베이스를 원하는 형식으로 변환하여 필요한 정보만 자동으로 획득할 수 있습니다. 데이터를 선택적으로 익명화, 가명화, 비식별화할 수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (EMR 의료 데이터 추출 정리 및 시각화) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example4.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">EMR 의료 데이터 추출 정리 및 시각화</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              대량의 원문에서 의학 용어를 추출하여 데이터베이스화 및 시각화를 제공합니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (AI 모델 학습을 통한 더 정확도 높은 정보 추출) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example5.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">AI 모델 학습을 통한 더 정확도 높은 정보 추출</div>
            <div class="usecase-section-sub" style="font-weight: 400; font-size: 18px">AI가 복잡한 패턴을 학습합니다.</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              인공지능 개체명인식(NER) 엔진으로 복잡한 내용도 정확하게 정리합니다. 텍스트의 주요 내용을 인공지능 모델 학습으로 정확하게 추출하여 제공합니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (고객 특화 용어 관리) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example6.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">고객 특화 용어 관리</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              [딥러닝언어모델 + UMLS 의학용어]를 기반으로 전문 용어 추천 기능을 제공 편리한 통합 용어 관리를 제공하여 업무 효율이 향상됩니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (의료 도메인 특화 정확도 향상) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example7.png" class="d-flex mx-auto" style="width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">의료 도메인 특화 정확도 향상</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              NIH (미국 국립 보건원) UMLS (Unified Medical Language System) DB화 의학 용어 DB 및 지식 그래프를 구축하여 정확한 의료데이터 솔루션을 제공합니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (인공지능 학습 데이터도 편리하게 구축) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example9.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">인공지능 학습 데이터도 편리하게 구축</div>
            <div class="usecase-section-sub" style="font-weight: 400; font-size: 18px">AI 기반 실시간 인터페이스를 활용하여 몇번의 클릭만으로 학습데이터 구축</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              클릭과 드래그 인터페이스를 제공합니다. 마우스 클릭으로 완성하는 학습데이터, 인공지능 엔진과 함께 편하게 작업하세요.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (플랫폼과 연동한 인사이트 대시보드) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example10.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">플랫폼과 연동한 인사이트 대시보드</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              다양하게 데이터를 살펴볼 수 있는 실시간 필터링과 검색기능과 함께 통계 인사이트를 제공합니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (간편한 연동) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/recolumn_example11.png" class="d-flex mx-auto" style="width: 70%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">간편한 연동</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              엑셀 데이터 지원으로 타 시스템과의 연동이 간편합니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 메디서브 예시 -->
    <div class="d-flex align-start justify-center">
      <!-- 웹 -->
      <div class="d-none d-sm-flex flex-column align-center" style="width: 60%; padding: 160px 0px 0px 0px">
        <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-5">
          의료 분야 특화
        </div>
        <div style="font-size: 48px; font-weight: 700">
          의료 분야 프로젝트 레퍼런스
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center justify-center" style="width: 90%; padding: 60px 0px 30px 0px">
        <div style="font-size: 18px; font-weight: 600; color: #283593" class="text-center pb-5">
          의료 분야 특화
        </div>
        <div style="font-size: 24px; font-weight: 700" class="text-center">
          의료 분야 프로젝트 레퍼런스
        </div>
      </div>
    </div>

    <!-- section (OCR 처방전 텍스트 추출 ) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 80px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/medical1.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">OCR 처방전 텍스트 추출</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              처방전 사진에서 처방 의약품 정보와 질병정보를 OCR(optical character recognition)로 추출하여 쉽고 빠르게 처방전 정보를 확인할 수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section (의약품 정보 및 DUR 검색) -->
    <div class="usecase-section d-flex justify-center" style="background-color: white; padding: 160px 0px 240px">
      <div class="main-sections">
        <div class="usecase-section-card d-flex align-center" style="background-color: #E2EFF7; margin-bottom: 60px; padding: 80px; border-radius: 10px">
          <img src="@/assets/img/medical2.png" class="d-flex mx-auto" style="height: 100%; max-width: 100%">
        </div>
        <div class="d-flex">
          <div style="width: 50%">
            <div class="usecase-section-title" style="font-weight: 600; font-size: 24px">의약품 정보 및 DUR 검색</div>
          </div>
          <div style="width: 50%">
            <div class="usecase-section-description" style="font-size: 18px; word-break: keep-all">
              의약품 이름이나 코드로 검색 시 의약품 처방·조제 시 병용금기 등 의약품 안전성 관련 정보인 DUR(Drug Utilization Review)을 실시간으로 제공하여 부적절한 약물 사용을 사전에 점검할 수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    goRecolumn() {
      window.open('https://www.recolumn.com/')
      this.navDrawer = false
    },
  }
}
</script>

<style scoped>
div {
  word-break: keep-all;
}

.main-sections {
  width: 60%;
}

@media (min-width: 2501px) {
  .first-section-title-1 {
    font-size: 56px;
  }
  .first-section-title-2 {
    font-size: 28px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 60%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 2500px) and (min-width: 1905px) {
  .first-section-title-1 {
    font-size: 40px;
  }
  .first-section-title-2 {
    font-size: 20px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 60%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 1904px) and (min-width: 1501px) {
  .first-section-title-1 {
    font-size: 40px;
  }
  .first-section-title-2 {
    font-size: 20px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 70%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 1500px) and (min-width: 1265px) {
  .first-section-title-1 {
    font-size: 32px;
  }
  .first-section-title-2 {
    font-size: 18px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 80%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 1264px) and (min-width: 961px) {
  .first-section-title-1 {
    font-size: 40px;
  }
  .first-section-title-2 {
    font-size: 20px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 80%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 960px) and (min-width: 601px) {
  .first-section {
    background-size: 150% 130% !important;
  }

  .first-section-title-1 {
    font-size: 36px;
  }
  .first-section-title-2 {
    font-size: 18px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding-bottom: 48px !important;
  }
  .solution-col-2 {
    padding-bottom: 48px !important;
  }
  .solution-col-3 {
    padding-bottom: 48px !important;
  }
  .solution-col-4 {
    padding-bottom: 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 100%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 600px) {
  .first-section {
    background-size: 150% 130% !important;
  }

  .first-section-title-1 {
    font-size: 28px;
  }
  .first-section-title-2 {
    font-size: 14px;
  }

  .partner-section {
    height: auto !important;
    padding: 90px 0 60px 0 !important;
  }
  .partners {
    width: 100%;
  }
  .partner-box {
    width: 150px;
    height: 90px;
    margin: 24px auto;
  }

  .main-sections {
    width: 80% !important;
  }

  .solution-section {
    padding: 90px 0 30px 0 !important;
  }
  .solution-section-title-1 {
    font-size: 24px;
  }
  .solution-section-title-2 {
    font-size: 18px;
  }
  .solution-section-sub-1 {
    font-size: 16px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 12px;
  }
  .solution-col-1 {
    padding-bottom: 48px !important;
  }
  .solution-col-2 {
    padding-bottom: 48px !important;
  }
  .solution-col-3 {
    padding-bottom: 48px !important;
  }
  .solution-col-4 {
    padding-bottom: 48px !important;
  }

  .introduce-section {
    padding: 90px 0 30px 0 !important;
  }
  .introduce-section-title-1 {
    font-size: 32px !important;
  }
  .introduce-section-title-2 {
    font-size: 20px !important;
  }
  .introduce-section-img {
    width: 100% !important;
  }

  .feature-title {
    font-size: 32px !important;
  }
  .feature-section {
    padding: 60px 0 !important;
  }
  .feature-section-title {
    font-size: 20px !important;
    text-align: center !important;
  }
  .feature-section-sub {
    font-size: 14px !important;
    text-align: center !important;
  }

  .usecase-title {
    padding-top: 60px !important;
    font-size: 24px !important;
  }
  .usecase-section {
    padding: 60px 0 !important;
  }
  .usecase-section-card {
    padding: 30px !important;
  }
  .usecase-section-title {
    font-size: 20px !important;
  }
  .usecase-section-sub {
    font-size: 14px !important;
  }
  .usecase-section-description {
    font-size: 14px !important;
    padding-left: 8px;
  }

  .contact-section-title {
    font-size: 20px !important;
  }
  .contact-section-context {
    font-size: 12px !important;
  }

}

.shadow-box {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0,0,0, 0.07), 0 1px 5px 0 rgba(0,0,0, 0.07) !important;
}
.disabled-chip::before {
  display: none;
}
</style>